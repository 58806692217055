/* src/styles/Navigation.css */
.navigation {
  padding: 10px;
}

.navigation-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navigation-item {
  margin-right: 10px;
}

.navigation-link {
  display: block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navigation-link:hover {
  background-color: #333;
}

.navigation-link.active {
  background-color: #444;
  font-weight: bold;
}